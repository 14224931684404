<template>
  <div class="view home">
    <Splash></Splash>
    <div class="center padding">
      <!--<Section>
        <h1>Who are we</h1>
        <p>Parents who want to level the funding gap for fusion type cancers.</p>
        <a href="https://www.paypal.com/donate?hosted_button_id=6ZL839C7QCD5E"><button class="donate">Donate</button></a>
      </Section>-->
      <Section>
        <h1>Mission Statement</h1>
        <p>Pursue donations that will fund research and treatments for rare pediatric cancers. Due to the small number of rare pediatric cancer cases the funding is disparagingly lower than with other cancers. The goal of this foundation is to fund pediatric cancer research and treatments to a level comparable to other pediatric cancers.</p>
      </Section>
      <Section>
        <h1>Dylan's Story</h1>
        <p>Dylan Bradley Arnold loved to play baseball,solve rubik's cubes, play video games with his younger sister Lacey, work at his school in theater tech, and hang out with his friends.</p>
        <img src="@/assets/imgs/1.jpg" alt="Dylan" class="dylan">
        <img src="@/assets/imgs/2.jpg" alt="Dylan" class="dylan">
        <p>Dylan was diagnosed with metastatic Ewing Sarcoma on December 15th 2017. He had a tumor on his left scapula and two tumors in each lung. The following week he had surgery to insert a Hickman line and begin chemotherapy. The type of chemotherapy he was given was called <a href="https://www.cancer.org/cancer/ewing-tumor/treating/chemotherapy.html" target="_blank">VDC/IE</a> and continued until March 2018.  When he completed the  chemotherapy the decision was made to remove his left scapula (with the tumor attached) and put in an artificial scapula. He would lose up to 60-70 % of the function of his left arm but he wanted to look normal. The surgery was a success and after a week in the hospital he came back home and began physical therapy.</p>
        <p>Soon after new tumors began to appear and Dylan was receiving radiation for these new sites and began a new chemotherapy drug <a href="https://chemocare.com/chemotherapy/drug-info/Topotecan.aspx" target="_blank">topotecan</a> however the tumors had multiplied and became chemo resistant. On August 8 2018 at 10:08 PM my son Dylan Bradley Arnold succumbed to Ewing Sarcoma. During this time Dylan never complained about what he was going through. He was courageous and brave from the beginning to the end, never complaining about what he was going through and never wanting pity. Dylan did not like people to be in pain or sick. He wanted to help others any way he could and he wanted this disease and others like it to have a cure.</p>
        <p>As Dylan’s “Padre” as he called me, I decided after over months of nights spent researching these rare cancers, treatments, and outcomes something was wrong. From the research I did I feel that the pediatric cancer research for Ewing Sarcoma and other fusion type cancers are grossly underfunded and kids are suffering because of it. Dylan would want to do something about that so I decided to take on that goal. In April 2019 I began the process of creating The Sloth Foundation for Pediatric Cancer to do just that. To raise money for these rare fusion type cancers so that kids will not have to suffer and can have a normal life because that is what Dylan would want.</p>
        <p>Why the name The Sloth Foundation for Pediatric Cancer? Dylan loved Sloths.</p>
        <img src="@/assets/imgs/3.jpg" alt="Dylan" class="dylan">
      </Section>
      <Section class="types">
        <h1>Examples of Pediatric Cancer Types</h1>
        <ul class="tabs">
          <li
            v-for="(tab, index) in tabs"
            :class="{active: index === tabActive}"
            :key="index"
            @click="tabActive = index"
          >
            {{ tab.title }}
          </li>
        </ul>
        <p>{{ tabs[tabActive].description }}</p>
        <p style="text-indent: 0;">Excerpts from <a target="_blank" href="https://www.annualreviews.org/doi/pdf/10.1146/annurev-cancerbio-030518-055710">annualreviews.org</a></p>
      </Section>
      <Section class="sarcoma">
        <h1>Learn more about Pediatric Cancer</h1>
        <a target="_blank" href="http://www.danafarberbostonchildrens.org/conditions/solid-tumors/ewing-sarcoma.aspx?_vsignck&_vsrefdom=cancerewingsarcoma">Dana Farber Ewing Sarcoma</a>
        <a target="_blank" href="https://www.urmc.rochester.edu/encyclopedia/content.aspx?contenttypeid=34&contentid=19106-1">Ewing Sarcoma: Chemotherapy Information</a>
        <a target="_blank" href="https://www.annualreviews.org/doi/abs/10.1146/annurev-cancerbio-030518-055710">Biology and Therapy of Dominant Fusion Oncoproteins Involving Transcription Factor and Chromatin Regulators in Sarcomas</a>
        <a target="_blank" href="https://www.cancer.gov/types/bone/hp/ewing-treatment-pdq">National Cancer Institute Ewing Sarcoma</a>
        <a target="_blank" href="http://www.danafarberbostonchildrens.org/conditions/solid-tumors/osteosarcoma.aspx">Osteosarcoma</a>
      </Section>
    </div>
  </div>
</template>

<script>
import Splash from '@/components/Splash.vue';
import Section from '@/components/Section.vue';

export default {
  name: 'Home',
  components: {
    Splash,
    Section
  },
  data() {
    return {
      tabActive: 0,
      tabs: [
        {
          title: 'Ewing Sarcoma',
          description: 'A rare, aggressive bone tumor that most commonly occurs in children, adolescents,and young adults, ages 10 to 25 years; the average age of diagnosis is 15. The incidence of Ewing sarcoma has remained unchanged for 30 years with about 225 children and teenagers diagnosed in the United States each year (Esiashvili et al. 2008).'
        },
        {
          title: 'Rhabdomyosarcoma',
          description: 'The most common soft-tissue sarcoma in children and adolescents with approximately 350 new cases in the United States each year (Hettmer et al. 2014). RMS is a cancer of skeletal muscle phenotype, although the cell of origin is still debated and possibly heterogeneous(Arnold & Barr 2017). RM'
        },
        {
          title: 'Synovial Sarcoma',
          description: 'A rare malignancy of the soft tissue around the joints, with approximately 800 new cases diagnosed each year in the United States. It mainly affects adolescents and young adults with a peak age of disease occurrence at 15–29 years (Wang et al. 2017). There has been an increasing incidence, yet stagnant survival rates (∼56%), for this disease over the last three decades (Sultan et al. 2009,Wang et al. 2017). Treatment for this disease couples radical surgical excision, radiotherapy, and adjuvant chemotherapy, which provides a chance of cure for localized disease. However, the disease is prone to relapse, and metastatic disease is common and nearly always fatal (Sultan et al. 2009).'
        },
        {
          title: 'Osteosarcoma',
          description: 'Osteosarcoma is the most common type of bone cancer among children, adolescents, and young adults. It usually begins in the marrow cavity of the long bones, such as the arms and legs. It also can occur in the pelvis and spine, and in rare cases, in the jaw or other locations in the body. While osteosarcoma occurs most commonly in teenagers (when the rate of bone growth is the fastest), it can affect young children and adults as well. There are about 600 new cases of osteosarcoma diagnosed each year in the United States.'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
.sections {
  margin-top: 0;
}

.types p {
  text-align: left;
  text-indent: 30px;
}

.tabs {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
}

.tabs li {
  cursor: pointer;
  padding: 15px 25px;
  margin-right: 10px;
  border: solid 1px #ccc;
  border-radius: 5px;
}

.tabs li.active {
  border: solid 1px #32466B;
}

.sarcoma a {
  display: block;
  text-align: left;
  padding: 10px 0;
}

.dylan {
  width: calc(100% - 20px);
  max-width: 350px;
  border-radius: 5px;
  margin: 0 10px;
}

@media only screen and (max-width: 800px) {
  .tabs {
    flex-direction: column;
  }

  .tabs li {
    width: 100%;
  }

  .tabs li:not(:last-child) {
    margin-bottom: 20px;
  }
}
</style>
