<template>
  <div id="splash" :class="{active: active}">
    <div class="content">
      <h1>Fund dedicated research for rare pediatric cancers</h1>
      <img src="@/assets/imgs/icons/arrow-down-solid.svg" id="down">
    </div>
    <div class="background">
      <div class="color"></div>
      <div class="image"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Splash',
  data() {
    return {
      active: false
    }
  },
  created() {
    setTimeout(() => {
      this.active = true;
    }, 1000);
  }
}
</script>

<style scoped lang="scss">
$container-height: 600px;

.content {
  z-index: 3;
  position: absolute;
  width: 100vw;
  height: $container-height;
  color: white;
  font-weight: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.content h1 {
  font-size: 4em;
  width: 800px;
  text-align: center;
  text-shadow: 0 0 50px black;
  padding-top: 50px;
}

#down {
  z-index: 100;
  position: absolute;
  opacity: 0;
  height: 20px;
  width: 20px;
  bottom: -20px;
  transition: bottom 0.5s, opacity 0.5s;
}

.active #down {
  opacity: 1;
  bottom: 20px;
}

.color {
  z-index: 2;
  position: absolute;
  width: 100vw;
  height: $container-height;
  background: linear-gradient(180deg, rgba(27, 83, 187, 0.25), rgba(255, 255, 255, 0));
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.25);
  opacity: 0;
  transition: opacity 2s;
}

.active .color {
  opacity: 1;
}

.image {
  z-index: 1;
  background: url('./../assets/imgs/splashCompressed.jpg') no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  width: 100vw;
  height: $container-height;
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 1);
  filter: brightness(50%);
}

@media only screen and (max-width: 1000px) {
  .content h1 {
    width: 600px;
    font-size: 4em;
  }
}

@media only screen and (max-width: 800px) {
  .content h1 {
    font-size: 3em;
    width: 400px;
  }
}

@media only screen and (max-width: 600px) {
  .content h1 {
    font-size: 3em;
    width: 100vw;
    padding: 40px;
  }
}
</style>
