<template>
  <div id="app">
    <Nav></Nav>
    <router-view/>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue';

export default {
  name: 'App',
  components: {
    Nav
  }
}
</script>

<style lang="scss">
body {
  margin: 0;
  background: #fff;
}

#app {
  text-align: center;
  color: #212e3a;
}

*, button {
  font-family: 'Lato', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

.grecaptcha-badge {
  visibility: hidden;
}

.view {
  position: fixed;
  width: 100vw;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.center {
  display: flex;
  align-items: center;
  flex-direction: column;
  align-content: center;
  justify-content: center;
}

.center.padding {
  padding: 10px 0;
}

.row {
  display: flex;
  flex-direction: row;
}

.column {
  display: flex;
  flex-direction: column;
}

.justify {
  text-align: justify;
  text-indent: 20px;
  line-height: 150%;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

input, textarea {
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  font-size: 1em;
}

a, a:visited, a:active {
  color: #0e71bd;
}

button.donate {
  margin: 10px 0;
  max-width: 280px;
  width: 100%;
  height: 40px;
  background: #0e71bd;
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.2em;
  font-weight: bold;
}
</style>
