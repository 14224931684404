<template>
  <div id="nav">
    <div class="container">
      <div @click="route('/')" class="home row">
        <img id="logo" src="@/assets/imgs/logo.png" />
        <h1>SlothForPedCancer.org</h1>
      </div>
      <div class="links row">
        <div class="icon">
          <a
            href="https://www.youtube.com/channel/UCNaXJ4tNtRbJ9wSWuid_dzQ"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
              fill="white"
            >
              <title>Logo Youtube</title>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z"
              />
            </svg>
          </a>
        </div>
        <div class="icon">
          <a
            href="https://www.instagram.com/slothforpedcancer.org4/"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
              fill="white"
            >
              <title>Logo Instagram</title>
              <path
                d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"
              />
              <path
                d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"
              />
            </svg>
          </a>
        </div>
        <div class="icon">
          <a
            href="https://www.facebook.com/The-Slothfoundation-For-Pediatric-Cancer-112780824551460/"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
              fill="white"
            >
              <title>Logo Facebook</title>
              <path
                d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                fill-rule="evenodd"
              />
            </svg>
          </a>
        </div>
        <div @click="route('/about')">About</div>
        <div @click="route('/news')">News</div>
        <div @click="route('/financials')">Financials</div>
        <div @click="route('/donate')">Donate</div>
        <div @click="route('/contact')">Contact</div>
      </div>
      <button
        class="hamburger hamburger--slider"
        :class="{ 'is-active': active }"
        type="button"
        @click="toggle"
      >
        <span class="hamburger-box">
          <span class="hamburger-inner"></span>
        </span>
      </button>
    </div>
    <div class="menu center" ref="menu">
      <ul>
        <li @click="route('/')">Home</li>
        <li @click="route('/about')">About</li>
        <li @click="route('/news')">News</li>
        <li @click="route('/financials')">Financials</li>
        <li @click="route('/donate')">Donate</li>
        <li @click="route('/contact')">Contact</li>
        <li style="display: flex; justify-content: center; gap: 20px">
          <a
            href="https://www.youtube.com/channel/UCNaXJ4tNtRbJ9wSWuid_dzQ"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
            >
              <title>Logo Youtube</title>
              <path
                xmlns="http://www.w3.org/2000/svg"
                d="M508.64 148.79c0-45-33.1-81.2-74-81.2C379.24 65 322.74 64 265 64h-18c-57.6 0-114.2 1-169.6 3.6C36.6 67.6 3.5 104 3.5 149 1 184.59-.06 220.19 0 255.79q-.15 53.4 3.4 106.9c0 45 33.1 81.5 73.9 81.5 58.2 2.7 117.9 3.9 178.6 3.8q91.2.3 178.6-3.8c40.9 0 74-36.5 74-81.5 2.4-35.7 3.5-71.3 3.4-107q.34-53.4-3.26-106.9zM207 353.89v-196.5l145 98.2z"
              />
            </svg>
          </a>
          <a
            href="https://www.instagram.com/slothforpedcancer.org4/"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
            >
              <title>Logo Instagram</title>
              <path
                d="M349.33 69.33a93.62 93.62 0 0193.34 93.34v186.66a93.62 93.62 0 01-93.34 93.34H162.67a93.62 93.62 0 01-93.34-93.34V162.67a93.62 93.62 0 0193.34-93.34h186.66m0-37.33H162.67C90.8 32 32 90.8 32 162.67v186.66C32 421.2 90.8 480 162.67 480h186.66C421.2 480 480 421.2 480 349.33V162.67C480 90.8 421.2 32 349.33 32z"
              />
              <path
                d="M377.33 162.67a28 28 0 1128-28 27.94 27.94 0 01-28 28zM256 181.33A74.67 74.67 0 11181.33 256 74.75 74.75 0 01256 181.33m0-37.33a112 112 0 10112 112 112 112 0 00-112-112z"
              />
            </svg>
          </a>
          <a
            href="https://www.facebook.com/The-Slothfoundation-For-Pediatric-Cancer-112780824551460/"
            target="_blank"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="ionicon"
              viewBox="0 0 512 512"
            >
              <title>Logo Facebook</title>
              <path
                d="M480 257.35c0-123.7-100.3-224-224-224s-224 100.3-224 224c0 111.8 81.9 204.47 189 221.29V322.12h-56.89v-64.77H221V208c0-56.13 33.45-87.16 84.61-87.16 24.51 0 50.15 4.38 50.15 4.38v55.13H327.5c-27.81 0-36.51 17.26-36.51 35v42h62.12l-9.92 64.77H291v156.54c107.1-16.81 189-109.48 189-221.31z"
                fill-rule="evenodd"
              />
            </svg>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import './hamburgers.css';

export default {
  name: 'Nav',
  data() {
    return {
      active: false,
      disabled: false
    };
  },
  methods: {
    route(path) {
      if (this.active) this.toggle();
      this.$router.push(path).catch(() => {});
    },
    toggle() {
      if (this.disabled) return;
      this.disabled = true;
      this.active = !this.active;
      if (this.active) {
        this.$refs.menu.style.display = 'flex';
        setTimeout(() => {
          this.$refs.menu.style.opacity = 1;
          this.disabled = false;
        }, 50);
      } else {
        this.$refs.menu.style.opacity = 0;
        setTimeout(() => {
          this.$refs.menu.style.display = 'none';
          this.disabled = false;
        }, 300);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#nav {
  top: 0;
  position: fixed;
  z-index: 10000;
  width: 100vw;
  height: 100px;
  background: white;
  color: #fff;
  box-shadow: 0 -5px 20px 0 rgba(0, 0, 0, 0.1);
  border-bottom: solid 2px #fff;
  background: #005596;
}

.container {
  width: 100vw;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .links div {
    margin: auto 10px;
    padding: 5px;
    cursor: pointer;
    text-align: center;
    font-weight: bold;
    a {
      text-decoration: none;
      color: inherit;

      svg {
        color: white;
        height: 25px;
        line-height: 0;
      }
    }
  }

  .links .icon {
    margin: auto 0;
    width: 40px;
  }

  .hamburger {
    display: none;
  }
}

.home {
  cursor: pointer;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 60px;
    height: 60px;
    border: solid 5px #eee;
    border-radius: 50%;
  }

  h1 {
    margin-left: 20px;
  }
}

.menu {
  background: rgba(233, 233, 233, 0.95);
  height: calc(100vh - 100px);
  width: 100vw;
  backdrop-filter: blur(5px);
  font-weight: bold;
  display: none;
  opacity: 0;
  transition: opacity 0.3s;
  color: #212e3a;

  li {
    max-width: 300px;
    font-size: 1.5em;
    padding: 20px 0;
    cursor: pointer;

    a {
      text-decoration: none;
      color: inherit;
      height: 100%;

      svg {
        width: 30px;
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .links {
    display: none;
  }

  .container {
    .hamburger {
      display: block;
    }
  }
}

@media only screen and (max-width: 550px) {
  .home h1 {
    display: none;
  }
}
</style>
